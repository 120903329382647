import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function About({data}) {
    return (
        <>
            <Page title={"Om oss"}>
                <div className="py-12 bg-white">
                    <div className="container max-w-screen-xl m-auto px-6 text-gray-600 md:px-12 xl:px-6">
                        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                            <div className="md:7/12 lg:w-6/12">
                                <h1 className="text-2xl text-gray-900 font-bold md:text-4xl">Om oss</h1>
                                <p className="mt-6 text-gray-600">Studentmottagningen grundades för att hjälpa studenter och föräldrar att planera och organisera en minnesvärd studentfest.</p>
                                <p className="mt-4 text-gray-600">Vi finns för att ge dig all nödvändig information, resurser och stöd för att ditt evenemang ska bli framgångsrik och minnesvärd succé.</p>
                                <p className="mt-4 text-gray-600">Vi förstår att det kan kräva mycket arbete att planera en student och därför finns vi här för att hjälpa dig genom varje steg i processen.</p>                                 
                                <p className="mt-4 text-gray-600">Vid frågor så kan du kan kontakta oss på: <a href="mailto:studentmottagningen@gmail.com">studentmottagningen@gmail.com</a></p>

                            </div>
                            <div className="md:5/12 lg:w-5/12">
                            <GatsbyImage image={data.homeTop.childImageSharp.gatsbyImageData} className={"rounded-2xl"} alt="studenter"/>
                            </div>
                        </div>
                    </div>
                </div>
               
            </Page>
        </>
    );
}


export const pageQuery = graphql`
  query {
    homeTop: file(relativePath: { eq: "studenthattar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
